import React, {ChangeEvent, FC, useCallback, useContext, useMemo, useState} from "react";
import {
  Box, Button, Checkbox, Divider, Grid, List, Modal, Paper, TextField, Typography,
} from "@mui/material";
import { KeyshapeItem } from "./components/KeyshapeItem";
import {getErrorMessage, notifyUser} from "../../../../../../utils/notificationCenter";
import axios from "axios";
import {useSnackbar} from "notistack";
import {UserContext} from "../../../../../../context/user-context";
import {
  EngineSelect
} from "../../../../../animation_board/components/control_panel/components/data_input_panel/components/EngineSelect";
import {AssetType} from "../../../../../../utils/sessionDataLoader";

export type AssetInfo = {
  "name": string,
  "public": boolean,
}

export interface AssetCreateBoardProps {
  userAssets: AssetType[],
  refreshAssets: (forceReload: boolean) => void,
}

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const ASSET_CREATE_ROUTE = process.env.REACT_APP_ASSET_CREATE_ROUTE!
const ASSET_STORE_ROUTE = process.env.REACT_APP_ASSET_STORE_ROUTE!

export const AssetCreateBoard: FC<AssetCreateBoardProps> = ({userAssets, refreshAssets}) => {
  const [requestData] = useState<FormData>(new FormData());
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [spammingProtection, setSpammingProtection] = useState<boolean>(false);
  const [allProvided, setAllProvided] = useState<boolean>();
  const {user} = useContext(UserContext);
  const [alreadyExist, setAlreadyExist] = useState<boolean>(false);
  const [overwriteEngine, setOverwriteEngine] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [fileUploaded, setFileUploaded] = useState<number>(0);
  const labels = useMemo(() => [
    'neutral', 'a_max', 'a_moy', 'i_max', 'i_moy', 'eu_euf', 'u_ou', 'w_oua','o_o', 's_ch', 'mbp', 'mbp_prot', 'f_v', 's_z'
  ], [])
  const [missingKeyshapes, setMissingKeyshapes] = useState<string[]>(labels);

  const refreshState = useCallback(() => {
    let newState = true
    const missingStates: string[] = [];
    labels.forEach((value) => {
      const keyState = requestData.has(value);
      if (!keyState) {
        missingStates.push(value);
      }
      newState = keyState;
    })
    setAllProvided(newState);
    setMissingKeyshapes(missingStates);
  }, [labels, requestData])

  const [info, setInfo] = useState<AssetInfo>({
    name: "",
    public: false,
  });

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    const assetIdx = userAssets.findIndex((asset) => {
      return asset.internalLabel.toLowerCase() === (newName + "_" + user?.email).toLowerCase()
    });
    if (assetIdx !== -1) {
      setAlreadyExist(true);
      setOverwriteEngine(userAssets[assetIdx].engines.includes(requestData.get("engine") as string))
    } else {
      setAlreadyExist(false)
      setOverwriteEngine(false)
    }
    setInfo({
      ...info,
      name: newName,
    });
  };

  const checkEngineState = (newEngine: string) => {
    const assetIdx = userAssets.findIndex((asset) => {
      return asset.internalLabel.toLowerCase() === (info.name + "_" + user?.email).toLowerCase()
    });
    if (assetIdx !== -1) {
      setOverwriteEngine(userAssets[assetIdx].engines.includes(newEngine))
    } else {
      setOverwriteEngine(false)
    }
  }

  const handlePublicChange = (_: ChangeEvent<HTMLInputElement>) => {
    setInfo({
      ...info,
      public: !info.public,
    });
  };

  const submitAsset = async () => {
    if (user) {
      setSpammingProtection(true);
      notifyUser("Sending asset to server...", false, enqueueSnackbar, closeSnackbar);
      let tmp_uuid = "";
      let break_loop = false;
      setModalOpen(true);
      for (const label of labels) {
        const fileFormData = new FormData();
        fileFormData.set("jwt", user.jwt);
        fileFormData.set("name", info.name);
        fileFormData.set("public", "" + info.public);
        fileFormData.set("asset_tmp_uuid", tmp_uuid);
        fileFormData.set(label, requestData.get(label) as File);

        requestData.set("asset_tmp_uuid", tmp_uuid);

        if (tmp_uuid === "") {
          await axios.post(
              CENTRAL_API_URL + ASSET_STORE_ROUTE,
              fileFormData,
              {
                headers: {"Content-Type": "multipart/form-data"}
              })
              // eslint-disable-next-line no-loop-func
              .then(res => {
                tmp_uuid = res.data.asset_tmp_uuid;
                setFileUploaded(1);
              })
              // eslint-disable-next-line no-loop-func
              .catch(error => {
                notifyUser("An error occurred while sending asset : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                setSpammingProtection(false);
                setModalOpen(false);
                break_loop = true;
              });
        } else {
          axios.post(
              CENTRAL_API_URL + ASSET_STORE_ROUTE,
              fileFormData,
              {
                headers: {"Content-Type": "multipart/form-data"}
              })
              // eslint-disable-next-line no-loop-func
              .then(res => {
                const file_index = labels.indexOf(label) + 1;
                if (file_index > fileUploaded) {
                  setFileUploaded(file_index)
                }
                if (file_index === labels.length) {
                  axios.post(
                      CENTRAL_API_URL + ASSET_CREATE_ROUTE,
                      {
                        "jwt": user.jwt,
                        "name": info.name,
                        "engine": requestData.get("engine"),
                        "public": "" + info.public,
                        "asset_tmp_uuid": requestData.get("asset_tmp_uuid"),
                      },
                  )
                      .then(_ => {
                        notifyUser("Asset successfully created !", false, enqueueSnackbar, closeSnackbar)
                        refreshAssets(true);
                        setModalOpen(false);
                        setSpammingProtection(false);
                      })
                      .catch(error => {
                        notifyUser("An error occurred while sending asset : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                        setSpammingProtection(false);
                        setModalOpen(false);
                      });
                }
              })
              // eslint-disable-next-line no-loop-func
              .catch(error => {
                notifyUser("An error occurred while sending asset : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                setSpammingProtection(false);
                setModalOpen(false);
                break_loop = true;
              });
        }

        if (break_loop) {
          break;
        }
      }
    }
  }

  let innerModalText = ""
  if (fileUploaded <= labels.length) {
    innerModalText = "Uploading asset keyshapes to server ... " + fileUploaded + "/" + labels.length;
  } else {
    innerModalText = "Linking asset to Dynalips ..."
  }

  return (
      <>
        <Modal
            open={modalOpen}
            onClose={() => {}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Uploading asset
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {innerModalText}
            </Typography>
          </Box>
        </Modal>
        <Box sx={{margin: 2, flexGrow: 1, border: 1, borderRadius: "4px", borderColor:'secondary.main', padding: "16px"}}>
          <Grid container spacing={2} sx={{textAlign: 'left', marginBottom: 1}} >
            <Grid item xs={12}>
              <Paper variant="outlined" sx={{textAlign: 'center'}} >
                <Typography variant="h5">Asset creator</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="center" sx={{maxHeight: 450, overflow: 'auto', padding: "16px"}}>
                <Grid item xs={12} sx={{textAlign: 'left'}}>
                  <Typography>Asset name</Typography>
                  <Paper variant={"outlined"}  sx={{marginTop: 0.5, marginBottom: 1}}>
                    <TextField value={info?.name} error={info?.name === ""} helperText={info?.name === "" ? "Please provide the asset name." : ""} onChange={handleNameChange} fullWidth size="small"/>
                  </Paper>
                </Grid>
                <Grid item xs={12} sx={{textAlign: 'left'}}>
                  <Typography>Select the engine from which the asset was exported.</Typography>
                  <EngineSelect requestData={requestData} currentAsset={undefined} onUpdate={checkEngineState}/>
                </Grid>
                <Grid item xs={12} sx={{textAlign: 'left'}}>
                  <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Typography>Make asset public ? This can be changed at any moment.</Typography>
                    <Checkbox color={"secondary"} checked={info?.public} onChange={handlePublicChange} inputProps={{ 'aria-label': 'controlled' }}/>
                  </Box>
                </Grid>
                {
                  alreadyExist ?
                      <Grid item xs={12}>
                        <Typography sx={{border:1, borderRadius: "4px", borderColor:'secondary.main', padding: 1, marginBottom: 1}} align='center'>{overwriteEngine ? "This asset already exist with this engine. You will overwrite existing data." : "This asset exist but not with this engine. This will allow you to use a new engine with this asset."}</Typography>
                      </Grid> : <></>
                }
                {
                  allProvided ? <></> :
                      <Grid item xs={12}>
                        <Typography sx={{border:1, borderRadius: "4px", borderColor:'secondary.main', padding: 1, marginBottom: 1}} align='center'>{"Please provide the following assets: [" + missingKeyshapes.join(', ') + "]"}</Typography>
                      </Grid>
                }
                <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <Button onClick={submitAsset} disabled={!allProvided || spammingProtection} sx={{fontSize: "1em"}} variant="contained">
                    <Typography sx={{textAlign: "center"}}>Create</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: "-1px", marginTop: 1, marginBottom: 1}} />
            <Grid item xs={6}>
              <Grid container justifyContent="center" sx={{maxHeight: 450, overflow: 'auto', padding: "16px"}}>
                <List sx={{width: "100%"}}>
                  <KeyshapeItem display={"1 - Neutral"} label={"neutral"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"2 - Max /a/"} label={"a_max"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"3 - Mean /a/"} label={"a_moy"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"4 - Max /i/"} label={"i_max"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"5 - Mean /i/"} label={"i_moy"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"6 - /ø/"} label={"eu_euf"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"7 - /u/"} label={"u_ou"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"8 - /w/"} label={"w_oua"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"9 - /o/"} label={"o_o"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"10 - /ʃ/"} label={"s_ch"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"11 - Normal /m/, /b/ and /p/"} label={"mbp"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"12 - Protrusion /m/, /b/ and /p/"} label={"mbp_prot"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"13 - /f/ and /v/"} label={"f_v"} requestData={requestData} refreshState={refreshState}/>
                  <KeyshapeItem display={"14 - /s/ and /z/"} label={"s_z"} requestData={requestData} refreshState={refreshState}/>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
  )
}