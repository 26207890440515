import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import background from './res/background.png';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import BaseBoard from "./demonstrator/DemonstratorApp";

const BASE_URL = process.env.REACT_APP_URL!
const DEMONSTRATOR_URL = process.env.REACT_APP_DEMONSTRATOR_URL!

export const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundImage:`url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "left top",
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#01012c",
                    textAlign: "justify"
                },
                arrow: {
                    color: "#01012c",
                }
            }
        }
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#01012c',
        },
        secondary: {
            main: '#8404a4',
        },
        background: {
            default: '#19184f',
            paper: '#340b64',
        },
    },
    typography: {
        fontFamily: 'Barlow Condensed',
        fontSize: 16,
    },
});

const innerHTML = window.location.href.includes(BASE_URL + DEMONSTRATOR_URL) ? <BaseBoard/> : <App/>

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {innerHTML}
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
