import {FC, useContext, useEffect, useRef, useState} from "react";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {getErrorMessage, notifyUser} from "../../utils/notificationCenter";
import axios from "axios";
import {useSnackbar} from "notistack";
import {SendContext} from "../../context/send-context";
import {DataProps} from "../DemonstratorApp";


interface Props {
    requestData: FormData
    setResults: (newElem: DataProps | ((prev: DataProps) => DataProps)) => void
}

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const DEMONSTRATOR_PROCESS_ROUTE = process.env.REACT_APP_DEMONSTRATOR_PROCESS_ROUTE!


export const AnimateButton: FC<Props> = ({setResults, requestData}) => {
    const {textProvided, spammingProtection, setSpammingProtection} = useContext(SendContext);
    const [isErrored, setErrored] = useState<boolean>(true);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const requestSent = useRef<boolean>(false);

    useEffect(() => {
        setErrored(!textProvided || spammingProtection)
    }, [textProvided, spammingProtection])

    const buttonHandler = async () => {
        if (!requestSent.current) {
            setSpammingProtection(true);
            notifyUser("Sending animation request to server...", false, enqueueSnackbar, closeSnackbar);
            axios.post(
                CENTRAL_API_URL + DEMONSTRATOR_PROCESS_ROUTE,
                {
                    "text": requestData.get("text"),
                    "language": requestData.get("language")
                })
                .then(res => {
                    setSpammingProtection(false)
                    requestSent.current = false;
                    const byteCharacters = atob(res.data.audio);
                    const byteArrays = [];

                    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                        const byteArray = new ArrayBuffer(1024);
                        const byteArrayView = new Uint8Array(byteArray);
                        for (let i = 0; i < 1024 && offset + i < byteCharacters.length; i++) {
                            byteArrayView[i] = byteCharacters.charCodeAt(offset + i);
                        }
                        byteArrays.push(byteArrayView);
                    }

                    setResults({
                        data: res.data.data,
                        audio: new Blob(byteArrays, {type: "audio/wav"})
                    })
                })
                .catch(error => {
                    notifyUser("An error occurred while animating data : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                    setSpammingProtection(false)
                    requestSent.current = false;
                });
            requestSent.current = true;
        }
    }
    return (
        <Box sx={{alignSelf: 'center', height: "100%", display: 'flex', alignItems: "center"}}>
            <Button sx={{width: "100%"}} disabled={isErrored} variant="contained" onClick={buttonHandler} startIcon={<AutoFixHighIcon/>}>
                Animate
            </Button>
        </Box>
    )
}
