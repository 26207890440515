import {FC, useState} from "react";
import {Divider, Grid, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";
import {ApiKeyDeleteButton} from "./ApiKeyDeleteButton";

export interface ApiKeyProps {
  data: ApiKeyInfo,
  setData: (callback: ((prevRequests: ApiKeyInfo[]) => ApiKeyInfo[])) => void
}

export type ApiKeyInfo = {
  _id: string;
  date: string;
}

export const ApiKeyItem: FC<ApiKeyProps> = ({data, setData}) => {
  return (
    <ListItem sx={{backgroundColor: "#340b64CC", borderRadius: "8px", marginBottom: "16px"}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ListItemButton alignItems="flex-start">
            <ListItemText
              primary={"Key id: " + data._id + " - Created on : " + new Date(data.date)}
              sx={{ my: 0 }}
            />
            <ApiKeyDeleteButton self={data} setData={setData}/>
          </ListItemButton>
          <Divider/>
        </Grid>
      </Grid>
    </ListItem>
  )
}