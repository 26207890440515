import React, {FC, useContext, useState} from "react";
import {
    Box, Button,
    Grid,
    List,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {getErrorMessage, notifyUser} from "../../../../utils/notificationCenter";
import axios from "axios";
import {useSnackbar} from "notistack";
import {MailTargetFilter, MailTargetFilterInputs} from "./components/MailTargetFilter";
import {UserContext} from "../../../../context/user-context";

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const BULK_MAIL_ROUTE = process.env.REACT_APP_BULK_MAIL_ROUTE!

export const MailerBoard: FC = () => {
    const {user} = useContext(UserContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [spammingProtection, setSpammingProtection] = useState<boolean>(false);

    const [targets, setTargets] = useState<{[label: string]: boolean}>({});
    const [mailContent, setMailContent] = useState<string>("");
    const [sender, setSender] = useState<string>("");
    const [senderPwd, setSenderPwd] = useState<string>("");
    const [allProvided, setAllProvided] = useState<boolean>();
    const [filters] = useState<MailTargetFilterInputs[]>([
        {
            label: "inactive_accounts",
            display: "Accounts that never used the platform",
        },
        {
            label: "newly_inactive_accounts",
            display: "Accounts that did not used the platform last month",
        },
        {
            label: "once_user_accounts",
            display: "Accounts that already used the platform",
        },
    ]);

    const handleSenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSender(event.target.value);
        setAllProvided(senderPwd !== "" && mailContent !== "" && event.target.value !== "");
    }

    const handleSenderPwdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSenderPwd(event.target.value);
        setAllProvided(sender !== "" && mailContent !== "" && event.target.value !== "");
    }

    const handleMailContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMailContent(event.target.value);
        setAllProvided(sender !== "" && senderPwd !== "" && event.target.value !== "");
    }

    const sendMail = () => {
        if (user) {
            setSpammingProtection(true);
            notifyUser("Sending bulk mail...", false, enqueueSnackbar, closeSnackbar);
            axios.post(
                CENTRAL_API_URL + BULK_MAIL_ROUTE,
                {
                    "jwt": user.jwt,
                    "sender": sender,
                    "senderPwd": senderPwd,
                    "content": mailContent,
                    "targets": targets,
                },
            )
                .then(_ => {
                    notifyUser("Mails successfully sent !", false, enqueueSnackbar, closeSnackbar)
                    setSpammingProtection(false)
                })
                .catch(error => {
                    notifyUser("An error occurred while sending mail : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                    setSpammingProtection(false)
                });
        }
    }

    return (
        <Box sx={{margin: 2, flexGrow: 1, border: 1, borderRadius: "4px", borderColor:'secondary.main', padding: "16px"}}>
            <Grid container spacing={2} sx={{textAlign: 'center'}} >
                <Grid item xs={12} md={8}>
                    <Paper variant="outlined" sx={{display: "flex", justifyContent: "space-around", marginBottom: 1}}>
                        <Typography variant="h5">Bulk mailer</Typography>
                    </Paper>
                    <Grid container spacing={2} sx={{textAlign: 'center', marginBottom: 1}} >
                        <Grid item xs={6}>
                            <Paper variant="outlined" sx={{display: "flex", justifyContent: "space-around"}}>
                                <Typography display="inline" alignSelf="center" sx={{flexGrow: 1}}>Sender :</Typography>
                                <TextField onChange={handleSenderChange}  sx={{flexGrow: 2}}/>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper variant="outlined" sx={{display: "flex", justifyContent: "space-around"}}>
                                <Typography display="inline" alignSelf="center" sx={{flexGrow: 1}}>Sender password:</Typography>
                                <TextField onChange={handleSenderPwdChange}  sx={{flexGrow: 2}} type="password"/>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Paper variant="outlined" sx={{display: "flex", justifyContent: "space-around"}}>
                        <TextField onChange={handleMailContentChange} fullWidth multiline/>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper variant="outlined" sx={{display: "flex", justifyContent: "space-around", marginBottom: 1}}>
                        <Typography variant="h5">Targets</Typography>
                    </Paper>
                    <Paper variant="outlined" sx={{display: "flex", justifyContent: "space-around", marginBottom: 1}}>
                        <List sx={{width: "100%"}}>
                            {filters.map((props: MailTargetFilterInputs) =>
                                <MailTargetFilter setTargets={setTargets} label={props.label} display={props.display}/>
                            )}
                        </List>
                    </Paper>
                    <Button onClick={sendMail} disabled={!allProvided || spammingProtection} sx={{fontSize: "1em"}} variant="contained">
                        <Typography sx={{textAlign: "center"}}>Send</Typography>
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}
