import React, {ChangeEvent, FC, useContext, useEffect, useState} from "react";
import {Box, Paper, Grid, Select, MenuItem, InputLabel, TextField} from "@mui/material";
import {AnimateButton} from "./AnimateButton";
import {SendContext} from "../../context/send-context";
import {SelectChangeEvent} from "@mui/material/Select";

export const InputsPanel: FC<{ requestData: FormData; setResults: any }> = ({ requestData, setResults }) => {
    const {setTextProvided} = useContext(SendContext);
    const [text, setText] = useState<string>("");
    const [language, setLanguage] = useState<string>("fr-fr");


    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
        requestData.set("text", event.target.value);
    }

    useEffect(() => {
        setTextProvided(text !== "");
    }, [setTextProvided, text]);

    return (
        <Grid
            container
            spacing={1}
            sx={{
                width: "100%",
                paddingTop: 2,
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {/* Sélecteur de langue (S'adapte en fonction de la taille de l'écran) */}
            <Grid item xs={12} sm={3} md={2}>
                <Paper
                    variant="outlined"
                    sx={{
                        borderRadius: 2,
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                    }}
                >
                    <InputLabel sx={{ marginLeft: 1, marginRight: 1, whiteSpace: "nowrap" }}>Language :</InputLabel>
                    <Box sx={{ flexGrow: 1 }}>
                        <Select
                            fullWidth
                            value={language}
                            onChange={(e: SelectChangeEvent) => {
                                requestData.set("language", e.target.value);
                                setLanguage(e.target.value);
                            }}
                        >
                            <MenuItem value="fr-fr">French</MenuItem>
                            <MenuItem value="en-us">English</MenuItem>
                            <MenuItem value="de-de">German</MenuItem>
                        </Select>
                    </Box>
                </Paper>
            </Grid>

            {/* Zone de texte (Garde un bon ratio sur tous les écrans) */}
            <Grid item xs={12} sm={8} md={9}>
                <Paper
                    variant="outlined"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 2,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <TextField sx={{width: "100%"}} value={text} placeholder="Let the avatar say something !" onChange={handleTextChange}/>
                </Paper>
            </Grid>

            {/* Bouton d'envoi (Se repositionne en bas sur mobile) */}
            <Grid item xs={12} sm={12} md={1} display="flex" justifyContent="center">
                <AnimateButton requestData={requestData} setResults={setResults} />
            </Grid>
        </Grid>
    );
};
