import React, {FC, useContext, useEffect, useState} from "react";
import {Box, Paper, Checkbox, Typography, Grid} from "@mui/material";
import {TextControls} from "./components/text_input/TextControls";
import {LanguageSelect} from "./components/LanguageSelect";
import {TextBoxInput} from "./components/text_input/components/TextBoxInput";
import {TextValidationInput} from "./components/text_input/components/TextValidationInput";
import {AudioControls} from "./components/audio_input/AudioControls";
import {PlaygroundContext} from "../../../../../../context/playground-context";
import {PlaygroundSelect} from "./components/PlaygroundSelect";
import Tooltip from "@mui/material/Tooltip";
import {AssetSelect} from "./components/AssetSelect";
import {EngineSelect} from "./components/EngineSelect";
import {AssetType} from "../../../../../../utils/sessionDataLoader";
import axios from "axios";
import {getErrorMessage} from "../../../../../../utils/notificationCenter";
import {UserContext} from "../../../../../../context/user-context";
import {FramerateSelect} from "./components/FramerateSelect";

export enum WordType {
    LEX_WORD = 2,
}

const CENTRAL_API_URL = process.env.REACT_APP_CENTRAL_API_URL!
const SETTINGS_ALL_ROUTE = process.env.REACT_APP_SETTINGS_ALL_ROUTE!

interface DataInputPanelProps {
    setLength: (length: number | undefined) => void;
    requestData: FormData;
}

export const DataInputPanel: FC<DataInputPanelProps> = ({setLength, requestData}) => {
    const [validText, setValidText] = useState<string[]>([]);
    const [validationList, setValidationList] = useState<{[word: string] : WordType}>({});
    const [text, setText] = useState<string>("");
    const {playgroundEnabled, setPlaygroundEnabled} = useContext(PlaygroundContext);
    const [currentAsset, setCurrentAsset] = useState<AssetType | undefined>(undefined);
    const [preferredLanguage, setPreferredLanguage] = useState<string>("");
    const [preferredAsset, setPreferredAsset] = useState<string>("");
    const [preferredFramerate, setPreferredFramerate] = useState<string>("");
    const {user} = useContext(UserContext);

    useEffect(() => {
        if (Object.keys(validationList).length === 0) {
            setValidText([])
            requestData.set("text", text);
        }
    }, [requestData, validationList, text])

    useEffect(() => {
      if (user) {
        axios.post(CENTRAL_API_URL + SETTINGS_ALL_ROUTE, {
          "jwt": user.jwt,
        })
          .then(res => {
            setPreferredLanguage(res.data.preferredLanguage);
            setPreferredAsset(res.data.preferredAsset);
            setPreferredFramerate(res.data.preferredFramerate);
          })
          .catch(error => console.log("Error on fetching your settings from database : ", getErrorMessage(error)))
      }
    }, [user])


    let textComponent;
    if (!validText.length) {
        textComponent = <TextBoxInput text={text} setText={setText}/>
    } else {
        textComponent = <TextValidationInput validationList={validationList} setValidationList={setValidationList} validText={validText} setValidText={setValidText} setText={setText} requestData={requestData}/>
    }

    return (
        <>
            <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Tooltip  placement={"bottom"} arrow title={
                        <Typography sx={{m: 1}}>Playground allows you to test our lipsync for free on provided audio sample files, so you can see how our interface works and how good our lipsync is !</Typography>
                    }>
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <Checkbox color={"secondary"} checked={playgroundEnabled} onChange={() => setPlaygroundEnabled(!playgroundEnabled)} inputProps={{ 'aria-label': 'controlled' }}/>
                        <Typography>Enable playground to generate FREE files.</Typography>
                    </Box>
                </Tooltip>
                <Box sx={{flexGrow: 1}}/>
            </Box>
            <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <LanguageSelect requestData={requestData} preferredLanguage={preferredLanguage}/>
              <Box sx={{flexGrow: 1}}/>
              <PlaygroundSelect/>
            </Box>
            <AudioControls setLength={setLength} requestData={requestData}/>
            <TextControls text={text} validText={validText} setText={setText} setValidationList={setValidationList} setValidText={setValidText} requestData={requestData}/>
            <Paper variant="outlined" sx={{backgroundColor: "background.default", marginBottom: 1, width: "100%", display: 'inline-flex', alignItems: "center", border: 0, borderRadius: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                {textComponent}
            </Paper>
            <Grid container sx={{width: "100%", overflow: 'auto', marginLeft: 0, marginRight: 1, borderRadius: 2, marginBottom: 1}} spacing={1}>
              <Grid item xs={5}>
                <AssetSelect requestData={requestData} currentAsset={currentAsset} setCurrentAsset={setCurrentAsset} preferredAsset={preferredAsset}/>
              </Grid>
              <Grid item xs={3}>
                <EngineSelect requestData={requestData} currentAsset={currentAsset} onUpdate={() => {}}/>
              </Grid>
              <Grid item xs={4}>
                <FramerateSelect requestData={requestData} preferredFramerate={preferredFramerate}/>
              </Grid>
            </Grid>
        </>
    )
}
