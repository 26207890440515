import React, {FC, useContext, useEffect, useRef} from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {DataProps} from "../DemonstratorApp";
import {useSnackbar} from "notistack";
import {notifyUser} from "../../utils/notificationCenter";
import {SendContext} from "../../context/send-context";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";

const CARTOON_BLENDSHAPES: string[] = [
    "BSW_jawForward",
    "BSW_jawOpen",
    "BSW_mouthClose",
    "BSW_mouthFunnel",
    "BSW_mouthLowerDownLeft",
    "BSW_mouthLowerDownRight",
    "BSW_mouthPressLeft",
    "BSW_mouthPressRight",
    "BSW_mouthPucker",
    "BSW_mouthRollLower",
    "BSW_mouthRollUpper",
    "BSW_mouthShrugUpper",
    "BSW_mouthSmileLeft",
    "BSW_mouthSmileRight",
    "BSW_mouthStretchLeft",
    "BSW_mouthStretchRight",
    "BSW_mouthUpperUpLeft",
    "BSW_mouthUpperUpRight"
]

// const CARTOON_BLENDSHAPES: string[] = [
//     "Lupin_Base_A1",
//     "Lupin_Base_A2",
//     "Lupin_Base_E1",
//     "Lupin_Base_F1",
//     "Lupin_Base_Ou1",
//     "Lupin_Base_Ou2",
//     "Lupin_Base_Ou3",
//     "Lupin_Base_Ou4",
//     "Lupin_Custom_Amax",
//     "Lupin_Custom_Imax",
//     "Lupin_Custom_Mbp",
//     "Lupin_Custom_MbpProt"
// ]

export const ThreeScene: React.FC<{results: DataProps | undefined}> = ({results}) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {setSpammingProtection} = useContext(SendContext);
    const mountRef = useRef<HTMLDivElement>(null);
    const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
    const sceneRef = useRef<THREE.Scene | null>(null);
    const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
    const mixerRef = useRef<THREE.AnimationMixer | null>(null);
    const modelRef = useRef<THREE.Object3D | null>(null);
    const clip = useRef<any>(undefined);
    const blendshapesIndices = useRef<number[]>([]);
    const lipsyncAction = useRef<THREE.AnimationAction | null>(null);

    const audioLoader = new THREE.AudioLoader();
    const listener = new THREE.AudioListener();
    const audioListener = new THREE.Audio(listener);
    const animationStarted = useRef<boolean>(false);

    useEffect(() => {
        if (sceneRef.current || !mountRef.current) {
            // Scene already loaded
            return;
        }

        const scene = new THREE.Scene();
        const clock = new THREE.Clock()

        // Lumières
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);

        // Caméra
        const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
        camera.position.set(0, 1.5, -0.8);

        camera.lookAt(new THREE.Vector3(100, 2, 2));

        // Renderer
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.shadowMap.enabled = true;
        mountRef.current.appendChild(renderer.domElement);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.enablePan = false; // Désactive le déplacement latéral
        controls.enableRotate = true; // Garde la rotation
        controls.enableZoom = true; // Garde le zoom
        controls.minDistance = 0.25;
        controls.maxDistance = 0.40;
        controls.target.set(0, 1.6, 0);

        sceneRef.current = scene;
        cameraRef.current = camera;
        rendererRef.current = renderer;

        notifyUser("Loading scene...", false, enqueueSnackbar, closeSnackbar)
        setSpammingProtection(true);
        const loader = new FBXLoader();
        loader.load('model/Cartoon_Eyes_Animation.fbx', (object) => {
        // loader.load('model/Lupin_Rig.fbx', (object) => {
            object.scale.set(0.01, 0.01, 0.01);
            object.position.set(0, 0, 0);
            object.rotation.set(0, Math.PI, 0);
            scene.add(object);
            modelRef.current = object;
            mixerRef.current = new THREE.AnimationMixer(object);
            mixerRef.current.addEventListener('finished', function (_) {
                animationStarted.current = false;
                if (mixerRef.current && clip.current) {
                    if (lipsyncAction.current) {
                        lipsyncAction.current.stop();
                    }
                    mixerRef.current.uncacheAction(clip.current);
                    mixerRef.current.uncacheClip(clip.current);
                }
            })
            const eyesLoop = mixerRef.current.clipAction(object.animations[0]);
            eyesLoop.play();

            const mesh: any = object.children.find((child: any) => child.isMesh && child.morphTargetInfluences);
            const morphTargetNames = Object.keys(mesh.morphTargetDictionary);
            blendshapesIndices.current = CARTOON_BLENDSHAPES.map(value => morphTargetNames.indexOf("Man_Blendshapes." + value))
            setSpammingProtection(false);
        });

        const animate = () => {
            requestAnimationFrame(animate);
            controls.update();
            if (mixerRef.current) {
                mixerRef.current.update(clock.getDelta()); // Mise à jour du mixer
            }
            renderer.render(scene, camera);
        };

        const handleResize = () => {
            if (mountRef.current) {
                const { clientWidth, clientHeight } = mountRef.current;
                renderer.setSize(clientWidth, clientHeight);
                camera.aspect = clientWidth / clientHeight;
                camera.updateProjectionMatrix();
                animate();
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        window.dispatchEvent(new Event('resize'));

        return () => {
            //window.removeEventListener('resize', handleResize);
            if (mountRef.current) {
                sceneRef.current = null;
                mountRef.current.removeChild(renderer.domElement);
            }
        };
    }, []);

    const startAnimation = () => {
        if (!animationStarted.current && mixerRef.current) {
            animationStarted.current = true;
            const frameRate = 1/30;
            const morphTargetValues: number[][] = (results?.data as number[][])

            const times = morphTargetValues[0].map((_, index) => index * frameRate);

            const tracks = blendshapesIndices.current.map((blendshapesIndex, index) =>
                new THREE.NumberKeyframeTrack(`Man_Fused.morphTargetInfluences[${blendshapesIndex}]`, times, morphTargetValues[index])
            );

            clip.current = new THREE.AnimationClip('MorphAnimation', -1, tracks);
            lipsyncAction.current = mixerRef.current.clipAction(clip.current);
            lipsyncAction.current.setLoop(THREE.LoopOnce, 1);
            lipsyncAction.current.clampWhenFinished = true;

            const audio = results?.audio as Blob;
            const audioURL = URL.createObjectURL(audio);
            audioLoader.load(audioURL, (buffer) => {
                if (!mixerRef.current || !lipsyncAction.current) {
                    return
                }
                mixerRef.current.setTime(0);
                audioListener.setBuffer(buffer);
                audioListener.play();
                lipsyncAction.current.play();

                const interval = setInterval(() => {
                    if (!audioListener.isPlaying || !mixerRef.current) {
                        clearInterval(interval);
                        return;
                    }
                    mixerRef.current.setTime(audioListener.context.currentTime - (audioListener as any)._startedAt);
                }, 16);
            });
        }
    };

    useEffect(() => {
        if (results) {
            startAnimation();
        }
    }, [results]);

    return (
        <div ref={mountRef} style={{ width: '100%', height: '100%', minHeight: "50vh"}}/>
    );
};