import {FC, MouseEvent, useCallback, useContext, useRef, useState} from "react";
import {IconButton} from "@mui/material";
import {useSnackbar} from "notistack";
import {UserContext} from "../../../../../context/user-context";
import {getErrorMessage, notifyUser} from "../../../../../utils/notificationCenter";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import {ApiKeyInfo} from "./ApiKeyItem";

export interface ApiKeyDeleteButtonProps {
    self: ApiKeyInfo,
    setData: (callback: ((prevRequests: ApiKeyInfo[]) => ApiKeyInfo[])) => void
}

const ID_PROVIDER_URL = process.env.REACT_APP_ID_PROVIDER_API_URL!
const API_KEY_DELETE_ROUTE = process.env.REACT_APP_API_KEY_DELETE_ROUTE!

export const ApiKeyDeleteButton: FC<ApiKeyDeleteButtonProps> = ({self, setData}) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [disable, setDisabled] = useState<boolean>(false);
    const requestSent = useRef<boolean>(false);
    const {user} = useContext(UserContext);


    const deleteHandler = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (!requestSent.current && user) {
            setDisabled(true);
            notifyUser("Deleting key from server...", false, enqueueSnackbar, closeSnackbar)
            axios.post(ID_PROVIDER_URL + API_KEY_DELETE_ROUTE, {
                "jwt": user.jwt,
                "_id": self._id,
            })
                .then(() => {
                    setData((prevRequests: ApiKeyInfo[]) => {
                        const newRequests = [...prevRequests];
                        const idx = newRequests.indexOf(self);
                        newRequests.splice(idx, 1);
                        // Components are kept by React, this particular request component will see its data changes,
                        // so we need to re-enable the delete button as the layout deletion may not occur on this component
                        requestSent.current = false;
                        setDisabled(false);
                        return newRequests;
                    });
                })
                .catch(error => {
                    notifyUser("An error occurred while deleting key : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
                    requestSent.current = false;
                    setDisabled(false);
                });
            requestSent.current = true;
        }
    }, [closeSnackbar, enqueueSnackbar, self, setData, user]);

    return <IconButton disabled={disable} onClick={deleteHandler} edge="end" aria-label="delete-text" sx={{marginRight: "0px"}}>
        <DeleteIcon/>
    </IconButton>
}