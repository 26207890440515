import React, {FC, useEffect, useState} from "react";
import {FramerateType, loadFramerateData} from "../../../../../utils/sessionDataLoader";
import {searchDataFromLabel, SelectWithLoader} from "../../../../../utils/components/SelectWithLoader";

interface PreferredFramerateSelectProps {
  preferredFramerate: string,
  setPreferredFramerate: (newElem: string) => void,
  disabled: boolean
}

export const PreferredFramerateSelect: FC<PreferredFramerateSelectProps> = ({preferredFramerate, setPreferredFramerate, disabled}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [framerates, setFramerates] = useState<FramerateType[]>([]);
  const [currentFramerate, setCurrentFramerate] = useState<FramerateType | undefined>();

  useEffect(() => {
    if (!disabled && currentFramerate) {
      setPreferredFramerate(currentFramerate?.internalLabel);
    }
  }, [disabled, currentFramerate, setPreferredFramerate]);

  useEffect(() => {
    if (disabled && preferredFramerate) {
      setCurrentFramerate(searchDataFromLabel(preferredFramerate, framerates));
    }
  }, [disabled, framerates, preferredFramerate])

  return (
    <SelectWithLoader id={"preferred framerate"} disabled={disabled} dataLoaderFunction={loadFramerateData}
                      data={framerates} setData={setFramerates} currentData={currentFramerate} setCurrentData={setCurrentFramerate}
                      loading={loading} setLoading={setLoading}/>
  )
}