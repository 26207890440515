import React, {FC, useContext, useState} from 'react';
import {useEffect} from "react";
import {PlaygroundContext} from "../../../../../../../context/playground-context";
import {FramerateType, loadFramerateData} from "../../../../../../../utils/sessionDataLoader";
import {
    searchDataFromDisplay,
    searchDataFromLabel,
    SelectWithLoader
} from "../../../../../../../utils/components/SelectWithLoader";
import {Box} from "@mui/material";

interface FramerateSelectProps {
    requestData: FormData,
    preferredFramerate: string,
}

export const FramerateSelect: FC<FramerateSelectProps> = ({requestData, preferredFramerate}) => {
    const {playgroundEnabled, playgroundFile} = useContext(PlaygroundContext);
    const [framerates, setFramerates] = useState<FramerateType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentFramerate, setCurrentFramerate] = useState<FramerateType | undefined>();

    useEffect(() => {
        if (playgroundFile !== undefined && playgroundEnabled) {
            setCurrentFramerate(searchDataFromDisplay("120", framerates));
        }
    }, [playgroundFile, playgroundEnabled, framerates, requestData]);

    useEffect(() => {
        if (currentFramerate) {
            requestData.set("framerate", currentFramerate.internalLabel);
        }
    }, [currentFramerate, requestData]);

    useEffect(() => {
        if (preferredFramerate && framerates.length > 0) {
            setCurrentFramerate(searchDataFromLabel(preferredFramerate, framerates));
        }
    }, [preferredFramerate, framerates, setCurrentFramerate])


    return (
      <SelectWithLoader id={"framerate"} disabled={playgroundEnabled} dataLoaderFunction={loadFramerateData}
                        data={framerates} setData={setFramerates} currentData={currentFramerate} setCurrentData={setCurrentFramerate}
                        loading={loading} setLoading={setLoading} replaceCurrentOnLoad/>
    )
}