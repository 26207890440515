import React, {useEffect, useRef, useState} from "react";
import {Paper, Container} from "@mui/material";
import { SendContext } from "../context/send-context";
import {SnackbarProvider} from "notistack";
import {InputsPanel} from "./components/InputsPanel";
import {ThreeScene} from "./components/ThreeScene";

export interface DataProps {
    audio: Blob,
    data: number[][]
}

export const BaseBoard: React.FC = () => {
    const requestData = useRef(new FormData())
    const [results, setResults] = useState<DataProps | undefined>(undefined);
    const [spammingProtection, setSpammingProtection] = useState<boolean>(false);
    const [audioProvided, setAudioProvided] = useState<boolean>(false);
    const [textProvided, setTextProvided] = useState<boolean>(false);
    const [textVerified, setTextVerified] = useState<boolean>(false);

    useEffect(() => {
        requestData.current.set("language", "fr-fr")
    }, [])

    //<Grid item xs={12} md={6}>
    return (
        <SnackbarProvider
            maxSnack={4}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <SendContext.Provider value={{
                spammingProtection: spammingProtection,
                audioProvided: audioProvided,
                textProvided: textProvided,
                textVerified: textVerified,
                setSpammingProtection: setSpammingProtection,
                setAudioProvided: setAudioProvided,
                setTextProvided: setTextProvided,
                setTextVerified: setTextVerified,
            }}>

                <Container maxWidth={false} disableGutters sx={{height: "100%"}}>
                    <Paper sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "58vh"
                    }}>
                        <ThreeScene results={results}/>
                    </Paper>
                    <InputsPanel setResults={setResults} requestData={requestData.current} />
                </Container>
            </SendContext.Provider>
        </SnackbarProvider>
    );
};

export default BaseBoard;
