import {FC, useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../../../../context/user-context";
import axios from "axios";
import {getErrorMessage, notifyUser} from "../../../../utils/notificationCenter";
import {Box, Button, Grid, List, Paper, Typography} from "@mui/material";
import {ApiKeyInfo, ApiKeyItem} from "./components/ApiKeyItem";
import AddIcon from '@mui/icons-material/Add';
import {useSnackbar} from "notistack";

const ID_PROVIDER_URL = process.env.REACT_APP_ID_PROVIDER_API_URL!
const API_KEY_LIST_ROUTE = process.env.REACT_APP_API_KEY_LIST_ROUTE!
const API_KEY_CREATE_ROUTE = process.env.REACT_APP_API_KEY_CREATE_ROUTE!


export const ApiKeyBoard: FC = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const requestSent = useRef<boolean>(false);
  const createRequestSent = useRef<boolean>(false);
  const {user} = useContext(UserContext);
  const [keys, setKeys] = useState<ApiKeyInfo[]>([]);
  const [newKey, setNewKey] = useState<string>("");

  useEffect(() => {
    if (!requestSent.current && user) {
      axios.post(ID_PROVIDER_URL + API_KEY_LIST_ROUTE, {
        "jwt": user.jwt
      })
        .then(response => {
          setKeys(response.data);
        })
        .catch(error => {
          notifyUser("An error occurred while fetching api keys from database : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
        })
      requestSent.current = true;
    }
  }, [user]);

  const buttonHandler = () => {
    if (!createRequestSent.current && user) {
      axios.post(ID_PROVIDER_URL + API_KEY_CREATE_ROUTE, {
        "jwt": user.jwt
      })
        .then(response => {
          setNewKey(response.data.key);
        })
        .catch(error => {
          notifyUser("An error occurred while creating a new api key : " + getErrorMessage(error), true, enqueueSnackbar, closeSnackbar)
          createRequestSent.current = false;
        })
      createRequestSent.current = true;
    }
  }

  return (
    <Box sx={{margin: 2, flexGrow: 1, border: 1, borderRadius: "4px", borderColor:'secondary.main', padding: "16px"}}>
      <Grid container spacing={2} sx={{textAlign: 'center'}} >
        <Grid item xs={12}>
          <Paper variant="outlined">
            <Typography variant="h5">Api keys management</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {newKey !== "" ? (
              <Paper variant="outlined">
                <Typography variant="h5">{newKey}</Typography>
                <Typography variant="caption" sx={{ display: 'block' }}>Note this key somewhere as you won't be able to recover it otherwise.</Typography>
              </Paper>
          ) : (
              <Box sx={{alignSelf: 'center', display: 'flex'}}>
                <Button sx={{width: "100%"}} disabled={createRequestSent.current} variant="contained" onClick={buttonHandler} startIcon={<AddIcon/>}>
                  Create new api key
                </Button>
              </Box>
          )}
        </Grid>
        <Grid container justifyContent="center" sx={{maxHeight: 600, overflow: 'auto', padding: "16px"}}>
          <List sx={{width: "100%"}}>
            {keys.map((request: ApiKeyInfo) => <ApiKeyItem
              key={request._id}
              data={request}
              setData={setKeys}/>)}
          </List>
        </Grid>
      </Grid>
    </Box>
  )
}