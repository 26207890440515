import React, {FC, useState} from "react";
import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    Checkbox,
    ListItemText
} from "@mui/material";

export interface MailTargetFilterProps {
    setTargets: (newDict: {[label: string]: boolean} | ((prevDict: {[label: string]: boolean}) => {[label: string]: boolean})) => void,
    label: string,
    display: string,
}

export interface MailTargetFilterInputs {
    label: string,
    display: string,
}


export const MailTargetFilter: FC<MailTargetFilterProps> = ({setTargets, label, display}) => {
    const [checked, setChecked] = useState<boolean>(false);
    const handleToggle = () => () => {
        const newValue = !checked;
        setTargets(prevTargets => ({...prevTargets, [label]: newValue}));
        setChecked(newValue);
    }
    const labelId = `filter-${label}`

    return (
        <ListItem key={label} disablePadding sx={{backgroundColor: "#340b64CC", borderRadius: "8px"}}>
            <ListItemButton role={undefined} onClick={handleToggle()} dense>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </ListItemIcon>
                <ListItemText id={labelId} primary={display}/>
            </ListItemButton>
        </ListItem>
    )
}