import React, {useContext} from 'react';
import { Title } from "../../utils/components/Title";
import {UserContext} from "../../context/user-context";
import Typography from "@mui/material/Typography";
import {
    Box,
    Divider, Drawer,
    List,
    ListItem,
    ListItemButton,
} from "@mui/material";
import ListItemIcon from '@mui/material/ListItemIcon';
import SendIcon from '@mui/icons-material/Send';
import ListItemText from '@mui/material/ListItemText';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AnalyticsIcon from "@mui/icons-material/Analytics";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import {Link, Route, Routes} from "react-router-dom";
import {StatisticsBoard} from "./boards/statistics_board/StatisticsBoard";
import {NotFoundBoard} from "../not_found_board/NotFoundBoard";
import {AdministrationHomeBoard} from "./boards/administration_home_board/AdministrationHomeBoard";
import {ReloadAccountBoard} from "./boards/reload_account_board/ReloadAccountBoard";
import {ServerStatusBoard} from "./boards/server_status_board/ServerStatusBoard";
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import {CreateNotifBoard} from "./boards/create_notifs_board/CreateNotifBoard";
import GroupsIcon from '@mui/icons-material/Groups';
import {ManageGroupsBoard} from "./boards/manage_groups_board/ManageGroupsBoard";
import {MailerBoard} from "./boards/mailer_board/MailerBoard";

export const AdministrationBoard: React.FC = () => {
    const {user} = useContext(UserContext);

    let inner;
    if (user?.perm === "ADMIN") {
        inner = (
          <Box sx={{ display: 'flex', marginBottom: '80px', marginTop: '80px' }}>
              <Drawer variant={"persistent"} open={true} anchor={"left"} sx={{
                    width: 232,
                }} PaperProps={{
                    sx: {
                        width: "inherit",
                        paddingTop: "64px"
                    }
                }}>
                    <List>
                        <ListItem key={"reload-account"} disablePadding>
                            <Link to={"./reload_account"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <CurrencyExchangeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Reload an account"} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <Divider />
                        <ListItem key={"view-statistics"} disablePadding>
                            <Link to={"./stats"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <AnalyticsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"View statistics"} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem key={"view-servers-status"} disablePadding>
                            <Link to={"/admin/servers_status"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <SignalCellularAltIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"View servers status"} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <Divider />
                        <ListItem key={"create-notifs"} disablePadding>
                            <Link to={"/admin/create_notifs"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <NotificationAddIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Create a notification"} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem key={"manage-groups"} disablePadding>
                            <Link to={"/admin/manage_groups"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <GroupsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Manage groups"} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem key={"bulk-mailer"} disablePadding>
                            <Link to={"/admin/bulk_mailer"} style={{width: "inherit", color: 'inherit', textDecoration: 'inherit'}}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <SendIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Send bulk mails"} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    </List>
                </Drawer>

                <Routes>
                    <Route path="/" element={<AdministrationHomeBoard/>} />
                    <Route path="reload_account" element={<ReloadAccountBoard/>} />
                    <Route path="stats/*" element={<StatisticsBoard/>} />
                    <Route path="servers_status" element={<ServerStatusBoard/>} />
                    <Route path="create_notifs" element={<CreateNotifBoard/>} />
                    <Route path="manage_groups" element={<ManageGroupsBoard/>} />
                    <Route path="bulk_mailer" element={<MailerBoard/>} />
                    <Route path="*" element={<NotFoundBoard/>} />
                </Routes>
            </Box>
        )
    } else {
        inner = <Typography>Not enough permissions to see this page</Typography>
    }
    return (
        <>
            <Title text={"Dynalips - Administration"}/>
            {inner}
        </>
    )
}
